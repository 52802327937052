const ThunderIcon = () => {
  return (
    <svg
      width="59"
      height="84"
      viewBox="0 0 59 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_49)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.01566 83.0025C3.30066 82.7775 3.57066 82.5375 3.85566 82.3125C21.2557 72.2775 38.0707 60.9675 54.6757 49.6725C56.4457 48.4725 55.0807 46.0125 53.3857 45.7575C48.2407 44.9775 43.0807 44.2425 37.9357 43.4775C35.1157 43.4325 32.5957 42.4575 30.4057 40.5375C32.1457 37.8075 34.3057 35.2425 36.2707 32.6625C43.5307 23.1075 51.2407 13.8375 58.0807 3.98254C59.7007 1.65754 56.1907 -0.622459 54.4657 1.55254C53.6407 2.58754 52.8457 3.66754 52.0507 4.71754C35.5957 19.8525 19.9057 35.8575 2.97066 50.4525C2.23566 51.0825 2.40066 52.4775 3.40566 52.7475C10.4557 54.6675 17.7007 56.0625 24.9607 57.0225C16.8007 64.7175 8.82066 72.6975 1.24566 80.9475C0.0906566 82.2075 1.60566 84.0975 3.00066 82.9875L3.01566 83.0025ZM31.2607 57.0975C32.6707 55.8075 31.9357 53.4375 30.0007 53.2575C22.1257 52.5075 14.3707 52.0725 6.60066 50.6475C17.7307 39.4725 30.0757 29.4975 41.8057 18.8925C40.9057 20.1825 39.9907 21.4725 39.0757 22.7475C36.5407 26.3025 34.0207 29.8575 31.4707 33.3975C30.1357 35.2425 27.1357 37.8675 26.6407 40.1625C26.2357 42.0525 26.2807 44.6025 28.6807 45.3975C34.5757 47.3475 40.9507 48.2925 47.2057 49.2525C38.5057 54.9375 29.8057 60.6825 21.2557 66.6225C24.5707 63.4125 27.8857 60.2025 31.2607 57.0825V57.0975Z"
          fill="#006357"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_49">
          <rect
            width="57.6607"
            height="82.6472"
            fill="white"
            transform="translate(0.836426 0.676392)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThunderIcon;
