import './LandingChooseDestination.scss';

import { Flag, GlobeHemisphereEast } from '@phosphor-icons/react';
import {
  CountryFlag,
  getCountryName,
  getCountryNameTranslation,
  Resource,
  useCountryUrlResolver,
} from '@travelwin/core';
import Button from '@travelwin/core/src/components/Button';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchEventData, useAnalytics } from '../../../../analytics';
import { useNavigate } from '../../../../components/Link';
import {
  FeaturedCountry,
  getViewerCountryCode,
} from '../../../../services/resources/ResourceService';
import LandingChooseDestinationTabs from './Tabs/LandingChooseDestinationTabs';

const className = 'c-LandingChooseDestination';

enum Tab {
  DESTINATION = 'destination',
  MULTIDESTINATION = 'multi-destination',
}

const LandingChooseDestination = ({
  featuredCountries,
}: {
  featuredCountries: FeaturedCountry[];
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const countryUrlResolver = useCountryUrlResolver();
  const analyticsContext = useAnalytics();

  const countriesByTab = useMemo(() => {
    const countries: Record<Tab, FeaturedCountry[]> = {
      [Tab.DESTINATION]: [],
      [Tab.MULTIDESTINATION]: [],
    };

    featuredCountries.forEach((country: FeaturedCountry) => {
      const tab = country.multi_destination
        ? Tab.MULTIDESTINATION
        : Tab.DESTINATION;
      countries[tab].push(country);
    });

    return countries;
  }, [featuredCountries]);

  const [activeTab, setActiveTab] = useState<Tab>(
    countriesByTab[Tab.DESTINATION]?.length > 0
      ? Tab.DESTINATION
      : Tab.MULTIDESTINATION,
  );

  const trackCountryClicked = (country: Resource) => {
    getViewerCountryCode().then((viewerCountryCode: any) => {
      const trackingData: SearchEventData = {
        searchClick: 'featured destination',
        countryName: getCountryName(country.description),
        userDestination: getCountryName(`countries.${viewerCountryCode}`),
      };
      analyticsContext?.trackSearch(trackingData);
    });
  };

  const tabs = [
    {
      key: Tab.DESTINATION,
      label: t('landing.destination', 'Destination'),
      icon: <Flag className={`${className}__tab-icon`} />,
    },
    {
      key: Tab.MULTIDESTINATION,
      label: t('landing.multi_destination', 'Multi-Destination'),
      icon: <GlobeHemisphereEast className={`${className}__tab-icon`} />,
    },
  ];

  return (
    <div className={className}>
      <div className={`${className}__title-description`}>
        <h2 className={`${className}__title`}>
          {t(
            'landing.choose_destination',
            'Welcome to a world without roaming...',
          )}
        </h2>
        <span className={`${className}__description`}>
          {t(
            'landing.choose_destination_description',
            'Pick a data plan — from anywhere to everywhere!',
          )}
        </span>
      </div>
      {countriesByTab[Tab.MULTIDESTINATION]?.length > 0 &&
      countriesByTab[Tab.DESTINATION]?.length > 0 ? (
        <LandingChooseDestinationTabs
          values={tabs}
          active={activeTab}
          onClick={(tab) => setActiveTab(tab as Tab)}
        />
      ) : null}
      <div className={`${className}__content_container`}>
        {countriesByTab[activeTab].map((country) => (
          <div
            key={country.id}
            className={classNames(`${className}__content-item`, {
              [`${className}__content-item-only-one`]:
                countriesByTab[activeTab].length < 2,
            })}
            onClick={() => {
              trackCountryClicked(country);
              navigate(`/plan-selection/${countryUrlResolver(country)}`);
            }}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                trackCountryClicked(country);
                navigate(`/plan-selection/${countryUrlResolver(country)}`);
              }
            }}
          >
            <CountryFlag countryCode={country.alias} />
            <span className={`${className}__country_name`}>
              {getCountryNameTranslation(country.description)}
            </span>
          </div>
        ))}
      </div>
      <div className={`${className}__see-all-destinations`}>
        <Button
          onClick={() => navigate('/destinations')}
          variant="secondary"
          fluid
        >
          {t('landing.see_all_destinations', 'See all destinations')}
        </Button>
      </div>
    </div>
  );
};

export default LandingChooseDestination;
