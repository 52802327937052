import '../Landing.scss';

import { KeyboardKey, useConfig } from '@travelwin/core';
import classNames from 'classnames';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import FaqModal, {
  FaqCards,
  FaqCardsType,
} from '../../../components/FaqModal/FaqModal';
import { isDefaultBrand, useBrandSpecificComponent } from '../../../utils';

const className = 'p-Landing';

const B2BLandingFAQ = () => {
  const [toggleFaqModal, setFaqModal] = useState(false);
  const [faqCard, setFaqCard] = useState<FaqCards>(null);
  const { WHITELABEL_BRAND } = useConfig();

  const { t } = useTranslation();

  const FaqQuestion1Icon = useBrandSpecificComponent('FaqQuestion1Icon');
  const FaqQuestion2Icon = useBrandSpecificComponent('FaqQuestion2Icon');
  const FaqQuestion3Icon = useBrandSpecificComponent('FaqQuestion3Icon');

  const openFaqModal = (faqCard: FaqCards) => {
    setFaqModal(true);
    setFaqCard(faqCard);
  };

  const handleFaqKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    faqCard: FaqCards,
  ) => {
    if (event.key === KeyboardKey.Enter) {
      openFaqModal(faqCard);
    }
  };

  return (
    <div className={`${className}__page-content-wide`}>
      <div className={`${className}__esim-content`}>
        <h2
          className={classNames(`${className}__esim-explained-title`, {
            [`${className}__esim-explained-title--secondary-color`]:
              isDefaultBrand(WHITELABEL_BRAND),
            [`${className}__esim-explained-title--uppercase`]:
              isDefaultBrand(WHITELABEL_BRAND),
          })}
        >
          <Trans
            i18nKey="landing.esim_explained_title"
            defaults="<regular>eSIM</regular> Explained"
            components={{
              regular: <span style={{ textTransform: 'none' }} />,
            }}
          />
        </h2>
        <div className={`${className}__esim-card-container`}>
          <div
            tabIndex={0}
            className={`${className}__esim-explained-card`}
            onClick={() => openFaqModal(FaqCardsType.ESimExplained)}
            onKeyDown={(event) =>
              handleFaqKeyDown(event, FaqCardsType.ESimExplained)
            }
          >
            <FaqQuestion1Icon size={48} />
            <p
              className={classNames(`${className}__esim_bold`, {
                [`${className}__esim_bold--secondary-color`]:
                  isDefaultBrand(WHITELABEL_BRAND),
              })}
            >
              {t('landing.esim_explained_card1.title', 'What is eSIM?')}
            </p>
            <p>
              {t(
                'landing.esim_explained_card1.msg',
                'Learn more about eSIM and how it works.',
              )}
            </p>
          </div>
          <div
            tabIndex={0}
            className={`${className}__esim-explained-card`}
            onClick={() => openFaqModal(FaqCardsType.ESimDeviceInformation)}
            onKeyDown={(event) =>
              handleFaqKeyDown(event, FaqCardsType.ESimDeviceInformation)
            }
          >
            <FaqQuestion2Icon size={48} />
            <p
              className={classNames(`${className}__esim_bold`, {
                [`${className}__esim_bold--secondary-color`]:
                  isDefaultBrand(WHITELABEL_BRAND),
              })}
            >
              {t('landing.esim_explained_card2.title', 'Can I use eSIM?')}
            </p>
            <p>
              {t(
                'landing.esim_explained_card2.msg',
                'Check if your device is unlocked & eSIM-enabled.',
              )}
            </p>
          </div>
          <div
            tabIndex={0}
            className={`${className}__esim-explained-card`}
            onClick={() => openFaqModal(FaqCardsType.ESimPlanInfo)}
            onKeyDown={(event) =>
              handleFaqKeyDown(event, FaqCardsType.ESimPlanInfo)
            }
          >
            <FaqQuestion3Icon size={48} />
            <p
              className={classNames(`${className}__esim_bold`, {
                [`${className}__esim_bold--secondary-color`]:
                  isDefaultBrand(WHITELABEL_BRAND),
              })}
            >
              {t('landing.esim_explained_card3.title', 'How our plans work')}
            </p>
            <p>
              {t(
                'landing.esim_explained_card3.msg',
                'Learn more about validity, usage, and top-ups.',
              )}
            </p>
          </div>
        </div>
        <FaqModal
          on={toggleFaqModal}
          toggle={() => setFaqModal(false)}
          card={faqCard}
        />
      </div>
    </div>
  );
};

export default B2BLandingFAQ;
