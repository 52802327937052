import './LandingGetMoreSection.scss';

import { useTranslation } from 'react-i18next';

import StarsIcon from './StarsIcon';
import SwitchIcon from './SwitchIcon';
import ThunderIcon from './ThunderIcon';

const className = 'p-LandingGetMoreSection';

const LandingGetMoreSection = () => {
  const { t } = useTranslation();

  return (
    <div className={`${className}__container`}>
      <div className={`${className}__header`}>
        <div className={`${className}__header-title`}>
          {t('landing.get_more_section.title', 'Why Sim Local')}
        </div>
        <div className={`${className}__header-description`}>
          {t(
            'landing.get_more_section.description',
            'Sim Local partners with local providers in every country to deliver fast, affordable coverage with zero roaming charges',
          )}
        </div>
      </div>
      <div className={`${className}__cards`}>
        <div className={`${className}__card`}>
          <div
            className={`${className}__card-icon ${className}__card-icon--square`}
          >
            <ThunderIcon />
          </div>
          <div className={`${className}__card-title`}>
            {t('landing.get_more_section.card1.title', 'Connect Instantly')}
          </div>
          <div className={`${className}__card-description`}>
            {t(
              'landing.get_more_section.card1.description',
              'Activate a travel eSIM and free yourself of roaming charges.',
            )}
          </div>
        </div>
        <div className={`${className}__card`}>
          <div
            className={`${className}__card-icon ${className}__card-icon--rectangle`}
          >
            <StarsIcon />
          </div>
          <div className={`${className}__card-title`}>
            {t(
              'landing.get_more_section.card2.title',
              'Trusted Local Networks',
            )}
          </div>
          <div className={`${className}__card-description`}>
            {t(
              'landing.get_more_section.card2.description',
              'Unlock fast, reliable coverage in 150+ countries.',
            )}
          </div>
        </div>
        <div className={`${className}__card`}>
          <div
            className={`${className}__card-icon ${className}__card-icon--square`}
          >
            <SwitchIcon />
          </div>
          <div className={`${className}__card-title`}>
            {t(
              'landing.get_more_section.card3.title',
              'Switch Plans, Not Sim Cards',
            )}
          </div>
          <div className={`${className}__card-description`}>
            {t(
              'landing.get_more_section.card3.description',
              'Keep your existing SIM card to receive calls as usual.',
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingGetMoreSection;
