import './LandingFAQ.scss';

import { Button } from '@travelwin/core';
import { Trans, useTranslation } from 'react-i18next';

import FAQsWeb from '../../../../components/FAQs/FAQsWeb';
import { useExternalHomepageConfig } from '../../../../hooks/useExternalHomepageConfig';
import LandingSectionTitle from '../LandingSectionTitle/LandingSectionTitle';

const className = 'c-LandingFAQ';

const LandingFAQ = () => {
  const { t } = useTranslation();
  const { getLocalizedZendeskUrl } = useExternalHomepageConfig();

  const onSeeMore = () => {
    window.location.href = getLocalizedZendeskUrl();
  };

  return (
    <div className={className}>
      <LandingSectionTitle textAlign="center">
        <Trans
          i18nKey="landing.faqTitle"
          defaults="Top <regular>eSIM</regular> Questions, Answered"
          components={{
            regular: <span className={`${className}__title`} />,
          }}
        />
      </LandingSectionTitle>
      <div className={`${className}__faqs_container`}>
        <FAQsWeb />
      </div>
      <div className={`${className}__button-container`}>
        <Button onClick={onSeeMore}>
          {t('landing.faqSeeMore', 'See more')}
        </Button>
      </div>
    </div>
  );
};

export default LandingFAQ;
