import '../FaqCard.scss';

import { useTranslation } from 'react-i18next';

import { useBrandSpecificComponent } from '../../../utils';

const FaqCardEsimDeviceInformation = () => {
  const className = 'c-FaqCard';

  const { t } = useTranslation();

  const FaqQuestion2Icon = useBrandSpecificComponent('FaqQuestion2Icon');

  return (
    <div>
      <div className={`${className}__center`}>
        <FaqQuestion2Icon size={48} />
      </div>

      <h2 className={`${className}__faq_card_title`}>
        {t('landing.esim_explained_card2.title', 'Can I use eSIM?')}
      </h2>
      <div className={`${className}__faq_card_highlight_bold`}>
        <p>
          {t(
            'landing.esim_modal2.1',
            'To check if your device is eSIM-enabled:',
          )}
        </p>
      </div>

      <div className={`${className}__faq_card_bold`}>
        <p>{t('landing.esim_modal2.2', 'iOS:')}</p>
      </div>
      <p>
        {t(
          'landing.esim_modal2.3',
          'Go to Settings>General>About. If "Digital SIM" or "eSIM" is present, the device is eSIM-enabled. Most iPhones are eSIM-enabled.',
        )}
      </p>

      <div className={`${className}__faq_card_bold`}>
        <p>{t('landing.esim_modal2.4', 'Samsung:')}</p>
      </div>
      <p>
        {t(
          'landing.esim_modal2.5',
          'Go to Settings>Connections>SIM Card Manager. If “Add Mobile Plan” is available, your device is eSIM-enabled.',
        )}
      </p>

      <div className={`${className}__faq_card_bold`}>
        <p>{t('landing.esim_modal2.6', 'Huawei:')}</p>
      </div>
      <p>
        {t(
          'landing.esim_modal2.7',
          'Go to Settings>About phone>Show EID. If EID information is available, this device is eSIM-enabled.',
        )}
      </p>

      <div className={`${className}__faq_card_bold`}>
        <p>{t('landing.esim_modal2.8', 'Other Android Devices:')}</p>
      </div>
      <p>
        {t(
          'landing.esim_modal2.9',
          'Dial *#06# and press call. If an “EID” number is present, your device is eSIM-enabled.',
        )}
      </p>

      <div className={`${className}__faq_card_highlight_bold`}>
        <p>
          {t(
            'landing.esim_modal2.10',
            'How do I know if my device is unlocked?',
          )}
        </p>
      </div>

      <div className={`${className}__faq_card_bold`}>
        <p>{t('landing.esim_modal2.11', 'iOS:')}</p>
      </div>
      <p>
        {t(
          'landing.esim_modal2.12',
          'If you are an iPhone user, you can check your unlock status by going to Settings > General > About. If “No SIM restrictions” appears next to Carrier Lock, your iPhone is unlocked.',
        )}
      </p>

      <div className={`${className}__faq_card_bold`}>
        <p>{t('landing.esim_modal2.13', 'Other Devices:')}</p>
      </div>
      <p>
        {t(
          'landing.esim_modal2.14',
          'The best way to check if your device is unlocked is by contacting your current network provider, who will confirm your device status.',
        )}
      </p>

      <div className={`${className}__faq_card_highlight`}>
        <p>
          {t(
            'landing.esim_modal2.15',
            'Your device must be network-unlocked to use plans from other operators. Contact your operator to confirm your device status.',
          )}
        </p>
      </div>
    </div>
  );
};

export default FaqCardEsimDeviceInformation;
