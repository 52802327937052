import './LandingHeader.scss';

import {
  getCountryName,
  Resource,
  Toast,
  useConfig,
  useCountryUrlResolver,
  isDefaultBrand as checkIsDefaultBrand,
} from '@travelwin/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { SearchEventData, useAnalytics } from '../../../../analytics';
import EsimBundleSearchForm from '../../../../components/EsimBundleSearchForm/EsimBundleSearchForm';
import { useNavigate } from '../../../../components/Link';
import SentryEventReport from '../../../../monitoring/SentryEventReport/SentryEventReport';
import useErrorWatcher from '../../../../monitoring/useErrorWatcher';
import {
  getViewerCountryCode,
  useCountriesQuery,
} from '../../../../services/resources/ResourceService';

const className = 'c-LandingHeader';

const LandingSearch = () => {
  const [selectedCountry, setSelectedCountry] = useState<Resource | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { PRE_POPULATE_DESTINATION, WHITELABEL_BRAND } = useConfig();
  const countryUrlResolver = useCountryUrlResolver();
  const analyticsContext = useAnalytics();
  const { data: countries, error: countriesQueryError } = useCountriesQuery();
  const sentryEventInfo = useErrorWatcher(countriesQueryError);
  const isDefaultBrand = checkIsDefaultBrand(WHITELABEL_BRAND);

  useEffect(() => {
    const fetchCountryFromHeader = async () => {
      try {
        const viewerCountryCode = await getViewerCountryCode();
        const viewerCountry = countries?.find(
          (country) => country.alias === viewerCountryCode,
        );
        setSelectedCountry((currentCountryId) => {
          if (!currentCountryId && viewerCountry) {
            return viewerCountry;
          }
          return currentCountryId;
        });
      } catch {
        // This catch block intentionally left empty as the exception being caught is not critical to the program's execution.
      }
    };

    if (PRE_POPULATE_DESTINATION && countries && countries.length > 0) {
      fetchCountryFromHeader();
    }
  }, [PRE_POPULATE_DESTINATION, countries]);

  const handleCountrychange = (country: Resource | null) => {
    setSelectedCountry(country);

    if (!country) return;

    getViewerCountryCode().then((viewerCountryCode) => {
      const trackingData: SearchEventData = {
        searchClick: 'search box',
        countryName: getCountryName(country.description),
        userDestination: getCountryName(`countries.${viewerCountryCode}`),
      };

      analyticsContext?.trackSearch(trackingData);
    });

    navigate(`/plan-selection/${countryUrlResolver(country)}`);
  };

  return (
    <>
      <div
        className={classNames(`${className}__search-container`, {
          [`${className}__search-container--simlocal`]: isDefaultBrand,
        })}
      >
        <EsimBundleSearchForm
          className={className}
          countries={countries || []}
          selectedCountry={selectedCountry}
          onChange={handleCountrychange}
        />
      </div>
      {countriesQueryError && (
        <div className={`${className}__error-container`}>
          <Toast error>
            {t(
              'error.generic',
              'An error has occurred, please try again later',
            )}
          </Toast>
          <SentryEventReport eventInfo={sentryEventInfo} />
        </div>
      )}
    </>
  );
};

export default LandingSearch;
