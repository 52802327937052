import './LandingActivePlans.scss';

import { CaretRight } from '@phosphor-icons/react';
import { OrderStatus, Spinner } from '@travelwin/core';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '../../../../analytics';
import { Link } from '../../../../components/Link';
import { useUser } from '../../../../context/User';
import useOrdersQuery from '../../../../hooks/useOrdersQuery';
import { isExpired } from '../../../../utils/orderUtils';
import { SimListOfOneType } from '../../../Orders/components/SimList/SimListOfOneType';
import LandingSectionTitle from '../LandingSectionTitle/LandingSectionTitle';

const className = 'c-LandingActivePlans';

const SELECTED_ELEMENT_ID = {
  BtnGoToMyEsim: 'btn_go_to_my_esim',
} as const;

const LandingActivePlans = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useUser();
  const analyticsContext = useAnalytics();
  const { data: orders, isLoading: areOrdersLoading } = useOrdersQuery(
    isAuthenticated,
    [OrderStatus.Completed],
  );

  const activeSims = useMemo(
    () => orders?.filter((order) => !isExpired(order)) ?? [],
    [orders],
  );

  const handleSeeAllPlansClick = () => {
    const trackingData = {
      element_id: SELECTED_ELEMENT_ID.BtnGoToMyEsim,
      element_text: 'Go to My eSIM',
    };
    analyticsContext?.trackClickAction(trackingData);
  };

  if (!isAuthenticated || (activeSims.length === 0 && !areOrdersLoading)) {
    return null;
  }

  return (
    <div className={className}>
      <LandingSectionTitle size="small">
        {t('landing.your_plan', 'Your Plan')}
      </LandingSectionTitle>

      <div className={`${className}__content`}>
        {areOrdersLoading && (
          <div className={`${className}__loading`}>
            <Spinner />
          </div>
        )}
        {activeSims.length > 0 && (
          <>
            <SimListOfOneType
              data={activeSims.slice(0, 1)}
              isDefaultView={false}
              from="/"
            />
            <Link
              to="/orders"
              className={classNames(`${className}__button_all_plans`, 'button')}
              onClick={handleSeeAllPlansClick}
            >
              {t('landing.go_to_my_esim', 'Go to  My eSIM')}
              <CaretRight size={24} weight="bold" />
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default LandingActivePlans;
