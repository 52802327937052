import './FaqModal.scss';

import { Button } from '@travelwin/core';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import FaqCardEsimDeviceInformation from './FaqCardEsimDeviceInformation/FaqCardEsimDeviceInformation';
import FaqCardEsimExplained from './FaqCardEsimExplained/FaqCardEsimExplained';
import FaqCardEsimPlanInfo from './FaqCardEsimPlanInfo/FaqCardEsimPlanInfo';

interface Props {
  on: boolean;
  toggle: () => void;
  card: FaqCards;
}

export enum FaqCardsType {
  ESimExplained = 'FaqCardESimExplained',
  ESimDeviceInformation = 'FaqCardESimDeviceInformation',
  ESimPlanInfo = 'FaqCardESimPlanInfo',
}

export type FaqCards = null | FaqCardsType;

const FaqModal = ({ on, toggle, card }: Props) => {
  const className = 'c-FaqModal';
  const { t } = useTranslation();

  const onCloseButton = () => {
    toggle();
  };

  return (
    <Modal
      toggle={onCloseButton}
      on={on}
      closeOnBackgroundClick={true}
      closeOnEscapeClick={true}
    >
      <div>
        <div className={`${className}__faq_text`}>
          {card === FaqCardsType.ESimExplained && <FaqCardEsimExplained />}
          {card === FaqCardsType.ESimDeviceInformation && (
            <FaqCardEsimDeviceInformation />
          )}
          {card === FaqCardsType.ESimPlanInfo && <FaqCardEsimPlanInfo />}

          <div className={`${className}__center`}>
            <Button onClick={() => toggle()}>{t('label.ok', 'OK')}</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FaqModal;
