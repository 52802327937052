import './LandingSectionTitle.scss';

import { isDefaultBrand, useConfig } from '@travelwin/core';
import classNames from 'classnames';

type TextAlign = 'left' | 'center';
type TextSize = 'small' | 'normal';

type Props = {
  children: string | JSX.Element;
  textAlign?: TextAlign;
  size?: TextSize;
};

const className = 'c-LandingSectionTitle';

const LandingSectionTitle = ({
  children,
  textAlign = 'left',
  size = 'normal',
}: Props) => {
  const { WHITELABEL_BRAND } = useConfig();

  return (
    <h2
      className={classNames(
        className,
        `${className}--${size}`,
        `${className}--${textAlign}`,
        {
          [`${className}--secondary-color`]: isDefaultBrand(WHITELABEL_BRAND),
          [`${className}--uppercase`]: isDefaultBrand(WHITELABEL_BRAND),
        },
      )}
    >
      {children}
    </h2>
  );
};

export default LandingSectionTitle;
