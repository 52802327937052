import '../FaqCard.scss';

import { useTranslation } from 'react-i18next';

import { useBrandSpecificComponent } from '../../../utils';

const FaqCardEsimPlanInfo = () => {
  const className = 'c-FaqCard';

  const { t } = useTranslation();

  const FaqQuestion3Icon = useBrandSpecificComponent('FaqQuestion3Icon');

  return (
    <div>
      <div className={`${className}__center`}>
        <FaqQuestion3Icon size={48} />
      </div>

      <h2 className={`${className}__faq_card_title`}>
        {t('landing.esim_explained_card3.title', 'How our plans work')}
      </h2>
      <p>
        {t(
          'landing.esim_modal3.1',
          'All plans come with a validity period in days, which refers to how many days you may use the plan for.',
        )}
      </p>

      <p>
        {t(
          'landing.esim_modal3.2',
          'This validity is only applied when your plan has been activated, so you can purchase a plan and activate it when you’re ready to start using it.',
        )}
      </p>

      <p>
        {t(
          'landing.esim_modal3.3',
          'Note, that installing your plan requires an Internet connection. It’s best to do this on the day of travel.',
        )}
      </p>
    </div>
  );
};

export default FaqCardEsimPlanInfo;
