import { Trans, useTranslation } from 'react-i18next';

import { useExternalHomepageConfig } from '../../hooks/useExternalHomepageConfig';
import FAQs, { FAQsItem } from './FAQs';

const FAQsWeb = () => {
  const { t } = useTranslation();

  const { getHomepageUrl } = useExternalHomepageConfig();

  const COMPATIBLE_HANDSETS = getHomepageUrl('/support/compatible-handsets');
  const IOS = getHomepageUrl('/support/esim-activation-guide');
  const ANDROID = getHomepageUrl('/support/esim-activation-guide');

  const faqs: FAQsItem[] = [
    {
      question: t('faqsweb.question1', 'What is an eSIM? How does it work?'),
      answer: (
        <Trans
          i18nKey="faqsweb.answer1"
          defaults="An eSIM is a digital SIM that simplifies staying connected while traveling abroad, eliminating the need to swap physical SIM cards. More importantly, it means you can avoid expensive roaming charges. Simply download a plan and connect in a few taps!"
          components={{
            a: <a href={COMPATIBLE_HANDSETS} />,
          }}
        />
      ),
    },
    {
      question: t('faqsweb.question2', 'Is my phone compatible with eSIM'),
      answer: (
        <Trans
          i18nKey="faqsweb.answer2"
          defaults="Most smartphones today support eSIM technology, but there are still a few models that do not. Easily check if your smartphone or tablet is eSIM-compatible using our <a>compatibility checker</a>."
          components={{
            a: <a href={COMPATIBLE_HANDSETS} />,
          }}
        />
      ),
    },
    {
      question: t('faqsweb.question3', 'Why choose Sim Local?'),
      answer: (
        <Trans
          i18nKey="faqsweb.answer3"
          defaults="Sim Local connects you to trusted local networks in 150+ countries. With a plan from a local provider, you can ditch slow and expensive roaming products and unlock the fastest possible internet speeds."
        />
      ),
    },
    {
      question: t('faqsweb.question4', 'How to activate an eSIM'),
      answer: (
        <Trans
          i18nKey="faqsweb.answer4"
          defaults="An eSIM can be installed with a few changes in your phone settings—no physical SIM card swapping required! Check out our quick guides for installing on <ios>iOS</ios> and <android>Android</android>."
          components={{
            ios: <a href={IOS} />,
            android: <a href={ANDROID} />,
          }}
        />
      ),
    },
    {
      question: t(
        'faqsweb.question5',
        'How long does it take to activate an eSIM?',
      ),
      answer: (
        <Trans
          i18nKey="faqsweb.answer5"
          defaults="The fastest way to install an eSIM is through the Sim Local app. Download a plan and get online in a couple of minutes!"
        />
      ),
    },
    {
      question: t('faqsweb.question6', 'How to get an eSIM'),
      answer: (
        <Trans
          i18nKey="faqsweb.answer6"
          defaults="Getting an eSIM is super easy! Simply purchase a plan online and follow guided instructions in the app (or email sent to your inbox)."
        />
      ),
    },
    {
      question: t(
        'faqsweb.question7',
        'How many eSIMs can I have on my phone?',
      ),
      answer: (
        <Trans
          i18nKey="faqsweb.answer7"
          defaults="You can have multiple active plans (usually up to 10) on your phone and instantly switch between as you travel from country to country.  Some devices will limit the number of eSIMs you can store on your phone, so you may need to remove an old eSIM to activate a new one."
        />
      ),
    },
  ];

  return <FAQs faqsItems={faqs} />;
};

export default FAQsWeb;
