import '../FaqCard.scss';

import { useTranslation } from 'react-i18next';

import { useBrandSpecificComponent } from '../../../utils';

const FaqCardEsimExplained = () => {
  const className = 'c-FaqCard';

  const { t } = useTranslation();

  const FaqQuestion1Icon = useBrandSpecificComponent('FaqQuestion1Icon');

  return (
    <div>
      <div className={`${className}__center`}>
        <FaqQuestion1Icon size={48} />
      </div>

      <h2 className={`${className}__faq_card_title`}>
        {t('landing.esim_explained_card1.title', 'What is eSIM?')}
      </h2>
      <p>
        {t(
          'landing.esim_modal1.1',
          'An eSIM is a digital version of a SIM Card that enables you to download and install a plan from a network provider on any eSIM-capable device.',
        )}
      </p>

      <p>
        {t(
          'landing.esim_modal1.2',
          'With an eSIM, you are able to select a plan, activate, and use it, all from your device. You can keep your existing SIM Card to be able to receive calls.',
        )}
      </p>

      <div className={`${className}__faq_card_highlight`}>
        <p>
          {t(
            'landing.esim_modal1.3',
            'Your device must be network-unlocked to use plans from other operators. Contact your operator to confirm your device status.',
          )}
        </p>
      </div>
    </div>
  );
};

export default FaqCardEsimExplained;
