import './LandingHeader.scss';

import {
  Icon,
  isDefaultBrand as checkIsDefaultBrand,
  useConfig,
} from '@travelwin/core';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import {
  GOOGLE_ICON,
  TRIPADVISOR_ICON,
  TRIPADVISOR_RATING_URL,
} from '../../../../components/TrustedBy/TrustedBy';
import { getBrandSpecificShopImage } from '../../../../utils';
import SearchSection from './LandingSearch';

const className = 'c-LandingHeader';

const LandingHeader = () => {
  const { t } = useTranslation();
  const { WHITELABEL_BRAND } = useConfig();
  const isDefaultBrand = checkIsDefaultBrand(WHITELABEL_BRAND);

  const fullWidthLandingImage = getBrandSpecificShopImage(
    'fullWidthLanding',
    WHITELABEL_BRAND,
  );
  const landingImage = getBrandSpecificShopImage('landing', WHITELABEL_BRAND);

  const subtitle = t('landing.subtitle', '');

  return (
    <div
      className={classNames(`${className}__header-section-container`, {
        [`${className}__header-section-container--primary-background`]:
          isDefaultBrand,
      })}
      style={{
        backgroundImage: fullWidthLandingImage
          ? `url(${fullWidthLandingImage})`
          : undefined,
      }}
    >
      <div
        className={classNames(`${className}__header-container`, {
          [`${className}__header-container--simlocal`]: isDefaultBrand,
        })}
      >
        <div
          className={classNames(`${className}__header-section`, {
            [`${className}__header-section--simlocal`]: isDefaultBrand,
          })}
        >
          <div
            className={classNames(`${className}__header-image-container`, {
              [`${className}__header-image-container--simlocal`]:
                isDefaultBrand,
            })}
          >
            {landingImage ? (
              <img
                className={`${className}__header-image`}
                src={getBrandSpecificShopImage('landing', WHITELABEL_BRAND)}
                alt=""
              />
            ) : (
              <div className={`${className}__header-image-placeholder`} />
            )}
          </div>
          <div
            className={classNames(`${className}__tagline-container`, {
              [`${className}__tagline-container--simlocal`]: isDefaultBrand,
            })}
          >
            <h1
              data-testid="title-landing-page"
              className={classNames(`${className}__tagline-text`, {
                [`${className}__tagline-text--white`]:
                  fullWidthLandingImage || isDefaultBrand,
                [`${className}__tagline-text--simlocal`]: isDefaultBrand,
                [`${className}__tagline-text--with-shadow`]:
                  fullWidthLandingImage,
              })}
            >
              <Trans
                i18nKey="landing.title"
                defaults="Stay Connected, Anywhere. Just Like Home."
                components={{
                  highlight: (
                    <span
                      className={`${className}__tagline-text--highlighted`}
                    />
                  ),
                }}
              />
            </h1>
            {subtitle && (
              <div
                className={classNames(`${className}__tagline-subtitle`, {
                  [`${className}__tagline-subtitle--white`]:
                    fullWidthLandingImage || isDefaultBrand,
                  [`${className}__tagline-subtitle--with-shadow`]:
                    fullWidthLandingImage,
                })}
              >
                {subtitle}
              </div>
            )}
            <div>
              {!isDefaultBrand && (
                <div
                  className={classNames(`${className}__search-prompt`, {
                    [`${className}__search-prompt--white`]:
                      fullWidthLandingImage,
                    [`${className}__search-prompt--with-shadow`]:
                      fullWidthLandingImage,
                  })}
                >
                  {t(
                    'landing.search.countries_label',
                    'Where are you travelling?',
                  )}
                </div>
              )}
              <SearchSection />
            </div>
            {isDefaultBrand && (
              <div className={`${className}__tagline-reviews`}>
                <a
                  href={TRIPADVISOR_RATING_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name={TRIPADVISOR_ICON} color="color-neutral-7" />
                </a>
                <Icon name={GOOGLE_ICON} color="color-neutral-7" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
