import { type MetaFunction } from '@remix-run/node';
import { json, useLoaderData } from '@remix-run/react';
import { isDefaultBrand, useConfig } from '@travelwin/core';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';

import { useAnalytics } from '@/analytics';
import BackToTopButton from '@/components/BackToTopButton';
import Footer from '@/components/Footer';
import { Layout } from '@/components/Layout';
import LandingActivePlans from '@/pages/Landing/components/LandingActivePlans/LandingActivePlans';
import { LandingBlogPosts } from '@/pages/Landing/components/LandingBlogPosts';
import LandingChooseDestination from '@/pages/Landing/components/LandingChooseDestination/LandingChooseDestination';
import LandingFAQ from '@/pages/Landing/components/LandingFAQ';
import { LandingFindMyPlan } from '@/pages/Landing/components/LandingFindMyPlan';
import LandingGetMoreSection from '@/pages/Landing/components/LandingGetMoreSection/LandingGetMoreSection';
import LandingHeader from '@/pages/Landing/components/LandingHeader';
import LandingHelp from '@/pages/Landing/components/LandingHelp';
import { getLatestBlogPosts } from '@/services/BlogPostService/BlogPostService';
import { getFeaturedCountries } from '@/services/resources/ResourceService';
import { RootLoader } from '~/root';

import B2BLandingFAQ from '../../src/pages/Landing/components/B2BLandingFAQ';

export const loader = async () => {
  const [featuredCountriesResult, postsResult] = await Promise.allSettled([
    getFeaturedCountries(),
    getLatestBlogPosts({ limit: 3 }),
  ]);

  if (featuredCountriesResult.status === 'rejected') {
    throw featuredCountriesResult.reason;
  }

  return json({
    featuredCountries: featuredCountriesResult.value,
    posts: postsResult.status === 'fulfilled' ? postsResult.value : [],
  });
};

export const meta: MetaFunction<
  typeof loader,
  {
    root: RootLoader;
  }
> = ({ matches }) => {
  const rootLoaderData = matches.find((match) => match.id === 'root')?.data;
  invariant(rootLoaderData);

  const { BRAND_NAME } = rootLoaderData.config;

  return [
    {
      title: t('browser.title', 'Local eSIM Deals for Travel | {{brandName}}', {
        brandName: BRAND_NAME,
      }),
    },
    {
      name: 'description',
      content: t(
        'browser.description',
        'Avoid roaming charges with Sim Local. Great value local & regional Travel eSIMs to keep you connected, seamlessly, wherever you are.',
      ),
    },
  ];
};

export default function Index() {
  const { WHITELABEL_BRAND } = useConfig();
  const { featuredCountries, posts } = useLoaderData<typeof loader>();
  const { t } = useTranslation();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics?.trackHomepageView();
  }, [analytics]);

  return (
    <Layout>
      <LandingHeader />
      <LandingActivePlans />
      {featuredCountries.length > 0 ? (
        <LandingChooseDestination featuredCountries={featuredCountries} />
      ) : null}
      <LandingFindMyPlan />
      <LandingGetMoreSection />
      {isDefaultBrand(WHITELABEL_BRAND) ? <LandingFAQ /> : <B2BLandingFAQ />}
      {isDefaultBrand(WHITELABEL_BRAND) && <LandingHelp />}
      <LandingBlogPosts
        posts={posts}
        header={t('landing.news_title', 'Catch up with our news')}
      />
      <BackToTopButton />
      <Footer />
    </Layout>
  );
}
