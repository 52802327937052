import './LandingChooseDestinationTabs.scss';

import classNames from 'classnames';
import { ReactNode } from 'react';

const className = 'c-LandingChooseDestinationTabs';

type TabKey = string;

type Tab = {
  key: TabKey;
  label: string;
  icon: ReactNode;
};

type Props = {
  values: Tab[];
  active: TabKey;
  onClick: (key: TabKey) => void;
};

const LandingChooseDestinationTabs = ({ values, active, onClick }: Props) => {
  return (
    <div className={className}>
      {values.map((tab) => (
        <button
          key={tab.key}
          className={classNames(
            `${className}__button`,
            tab.key === active && `${className}__button--active`,
          )}
          onClick={() => onClick(tab.key)}
        >
          {tab.icon}
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default LandingChooseDestinationTabs;
