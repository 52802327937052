import '../Landing.scss';

import { Button, useConfig } from '@travelwin/core';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '../../../components/Link';
import { getBrandSpecificShopImage } from '../../../utils';

const className = 'p-Landing';

const LandingHelp = () => {
  const { WHITELABEL_BRAND } = useConfig();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const helpImage = getBrandSpecificShopImage('help', WHITELABEL_BRAND);

  const handleHelpClick = () => {
    navigate('/help');
  };

  return (
    <div className={`${className}__help-container`}>
      <div
        className={`${className}__help-image-container`}
        style={{
          backgroundImage: helpImage ? `url(${helpImage})` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: '0% 40%',
        }}
      ></div>
      <div className={`${className}__help-text-container`}>
        <div className={`${className}__help-text-content`}>
          <div className={`${className}__help-text-title`}>
            {t('landing.help.title', 'Help and Support')}
          </div>
          <div className={`${className}__help-text-description`}>
            {t(
              'landing.help.description',
              'Need help getting connected? Read frequently asked questions about eSIM, or contact us directly below.',
            )}
          </div>
          <div className={`${className}__help-text-button`}>
            <Button
              fluid
              style={{ backgroundColor: 'black' }}
              onClick={() => handleHelpClick()}
            >
              {t('landing.help.button', 'Help and Support')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHelp;
